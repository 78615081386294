import http, { encodeParams } from '@/utils/request'
import { IRoleDetail } from './role'
import { IEnterprise } from "@/api/enterprise";

export interface IInstrumentParams {
  ids: string
  nameOrCode?: string
  enterpriseId?: number
  departmentId?: number
  brand?: string
  status?: number
  managerId?: number
  custodianId?: number
  deviceGroupId?: number
  page: number
  perPage: number
  isExport?: number
  parentId: number
  hasChild?: number
  extraId?: number
  extraValue?: string
}

export interface IModifyInstrumentParams {
  id: number
  nameOrCode: string
  enterpriseId: number
  departmentId: number
  brand: string
  status: number
  managerId: number
  custodianId: number
  deviceGroupId: number
  parentId?: number
  page: number
  perPage: number
}

export interface ICreateInstrumentParams {
  enterpriseId: number
  departmentId: number
  brand: string
  status: number
  custodianId: number
  deviceGroupId: number
  name: string
  code: string | any
  extraFields: any
}

export interface IInstrument {

}

export interface ICreateInstrumentComponentParams {
  id: number
  name: string
  model: string
  buyDate: string
  brand: string
  code: string
}

export const instrumentApi = {
  getConfig: function () {
    return http.get('/config')
  },
  getInstruments: function (params: IInstrumentParams) {
    return http.get('/instrument' + encodeParams(params))
  },
  getSubInstruments: function (insId: any) {
    return http.get(`/instrument/child/${insId}`)
  },
  getInstrumentDetail: function (id: any) {
    return http.get(`/instrument/${id}`)
  },
  getInstrumentFields: function () {
    return http.get('/instrument/field')
  },
  createInstrument: function (params: ICreateInstrumentParams) {
    return http.post('/instrument', params)
  },
  modifyInstrument: function (params: IModifyInstrumentParams) {
    return http.put(`/instrument/${params.id}`, params)
  },
  deleteInstrument: function (id: number) {
    return http.delete(`/instrument/${id}`)
  },
  createInstrumentComponent: function (params: ICreateInstrumentComponentParams) {
    return http.post(`/instrument/${params.id}/component`, params)
  },
  modifyInstrumentComponent: function (params: ICreateInstrumentComponentParams) {
    return http.put(`/instrument/${params.id}/component`, params)
  },
  deleteInstrumentComponent: function (params: any) {
    return http.delete(`/instrument/${params.id}/component${encodeParams(params)}`)
  },
  relateInstrumentComponent: function (params: any) {
    return http.post(`/instrument/relate`, params)
  },
  getTentatives: function (params: any) {
    return http.get(`/instrument/${params.id}/tentative` + encodeParams(params))
  },
  importInstruments: function (file: any) {
    const fd = new FormData()
    fd.append('file', file)
    return http.post('/instrument/export', fd, {
      // @ts-ignore 调用回调方法 让浏览器不再报出这个错误
      'Content-Type': 'multipart/form-data'
    })
  },
  downloadTemplate: function () {
    return http.get(`/instrument/export`, { responseType: 'blob' })
  },
  history: function (id: any) {
    return http.get(`/instrument/${id}/history`)
  },
  multipleSetting: function (params: any) {
    return http.post(`/instrument/multi_admin`, params)
  },
  unbind: function (id: string) {
    return http.post(`/instrument/${id}/eslabel/unbind`)
  },
  light: function (id: string,color:string) {
    return http.post(`/instrument/${id}/eslabel/light/${color}`)
  },
  downloadIns: function (orderId: number) {
    return http.get(`/instrument/export/pending?orderId=${orderId || 0}`, { responseType: 'blob' })
  },
  getChildInstrument: function (id: number) {
    return http.get(`/instrument/child/${id}`)
  },
  scrapInstrument: function (ids: any[]) {
    return http.put(`/instrument/scrap`, {ids: ids})
  },
  getRelateFiles: function (insId: number, page: number, perPage: number) {
    return http.get(`/instrument/${insId}/relate_file?page=${page}&perPage=${perPage}`)
  },
  addRelateFile: function (insId: number, file: string, path: string) {
    return http.post(`/instrument/${insId}/relate_file`, {file: file, path: path})
  },
  deleteRelateFile: function (file_id: number) {
    return http.delete(`/instrument/relate_file/${file_id}/unrelate`)
  }
}
